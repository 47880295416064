.H-Sc-Container
    padding-top: 3rem,
    overflow: hidden
    // color: red
    h1
        font-weight: 700
        font-size: 3em
        color: #404040
        letter-spacing: 0.6rem
        @media(max-width: 768px)
            font-size: 2.5em   
        @media(max-width: 450px)
            font-size: 2rem
            letter-spacing: 0.3rem
        @media(max-width: 300px)
            font-size: 1.5rem
    p
        font-size: 2rem
        font-weight: 600
        @media(max-width: 768px)
            font-size: 1em
