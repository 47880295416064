// .tag
//     margin-bottom: 7%
//     max-height: 20vh !important
//     height:-moz-fit-content
//     height: fit-content
//     margin-top: 4%
//     @media (max-width: 600px)
//         margin-bottom: 15%
//         margin-top: 7%
//     .imgslider
//         width:-moz-fit-content
//         width: fit-content
//         margin-left: auto
//         margin-right: auto
//         .slide
//             width: 110px !important
//             @media (max-width: 600px)
//                 width: 110px !important
//                 outline-width: 0px
//             background: red
//                 object-fit: contain
//             img
//                 width: 100%
.tagC
    // width: 70%
    margin-bottom: 7%
    margin-top: 4%
    margin-left: auto
    margin-right: auto
    overflow: hidden
    @media (max-width: 600px)
        margin-bottom: 15%
        margin-top: 7%
    .imgsliderC
        width: 100%
        height: -moz-fit-content
        height: fit-content
        margin-left: auto
        margin-right: auto
        overflow: hidden
        .slideC
            // width: 160px !important
            width: -moz-fit-content
            width: fit-content !important
            width: -moz-fit-content
            object-fit: contain
            // aspect-ratio: 1
            text-align: center
            @media (max-width: 600px)
                // width: 110px !important
                outline-width: 0px
                text-align: center
            img
                width: 100%
                height: 100%
                object-fit: contain
                // width: 100%
                // height: 50vh
                // border-radius: 10px
                // @media (max-width: 1028px)
                //     height: 50vh
                // @media (max-width: 1540px)
                //     height: 42vh
                // @media (max-width: 870px)
                //     height: 33vh
                // @media (max-width: 700px)
                //     height: 20vh

