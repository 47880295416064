.AT-Container
    .AT-1
        height: 30vh
        background-image: url(../../Assets/images/yellow_banner.png)
        background-size: cover
        background-repeat: no-repeat
        display: flex
        align-items: center
        justify-content: center
        h1
            color: white
            font-size: 8vh
    .AT-2
        display: flex
        height: fit-content
        padding: 5%
        // padding-top: 10%
        column-gap: 5%
        align-items: center
        justify-content: center

        .AT-2-1
            p
                text-align: left
                font-size: 1.08rem
                line-height: 2rem
                font-weight: 400
                @media(max-width: 1028px)
                    font-size: 1rem
                    line-height: 1.3rem
                @media(max-width: 800px)
                    text-align: center
                    font-size: 1.2rem
                @media(max-width: 500px)
                    font-size: 0.9rem

        .AT-2-2
            @media(max-width: 800px)
                display: none
            img
                max-width: 453px
                max-height: 470px
                border-radius: 24px
                @media(max-width: 1028px)
                    width: 300px
                @media(max-width: 800px)
                    display: none
    .AT-3
        width: 394px
        height: 487px
        border: 1px solid black
        .AT-3-1
            width: 180px
            height: 180px
            border-radius: 50%
            background-image: url(../../Assets/images/dp.jpg)
            background-size: contain
            background-position-y: 10%
            position: relative
            top: 19%
            left: 24%
            border: 5px solid #FFEBEE
        .AT-3-2
            position: relative
            top: 20%
    .AT-4
        .AT-4-1
            h1
                color: #4285F4
                font-size: 2.9rem
    .red
        background: linear-gradient( rgba(239,83,80,1) 40%, rgba(255,255,255,1) 30%)
    .green
        background: linear-gradient( #66BB6A 40%, rgba(255,255,255,1) 30%)
