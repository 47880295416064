.H-SC-Container
    // padding-bottom: 5%
    width: 100vw !important
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    overflow: hidden
    margin: auto !important
    .H-SC-Header
        text-align: center
        // background-color: black
        // height: 400px
        width: 100%
        @media (max-width: 800px)
            height: fit-content
        @media (max-width: 500px)
            height: fit-content
        h3
            color: #404040
            font-size: 1.2rem
            letter-spacing: 0.2rem
            padding-top: 4%
            margin: 0% 2%
            font-weight: 700
            @media (max-width: 800px)
                font-size: 0.8rem
        h1
            // color: white
            font-size: 3rem
            margin: 1%
            @media (max-width: 800px)
                font-size: 1.4rem
    .H-SC-Body
        width: 70%
        position: relative
        top: -50%
