

.G-H
    z-index: 10
    position: fixed
    width: 100vw
    display: block
    height: 90px
    align-items: center
    // padding-left: 2%
    // padding-right: 2%
    background-color: white

    box-shadow: 0px 1px 7px #918f8f5f
    .G-H-N
        // width: -moz-fit-content
        // width: fit-content
        width: 100%
        list-style-type: none
        display: flex
        margin: 0%
        padding: 0%
        font-weight: 500
        // padding-inline-end: 2%
        // justify-content: space-around
        align-items: center
        // padding-right: 2%
        font-size: 1.2rem
        .G-H-L
            // width: -moz-fit-content
            // width: fit-content
            margin: auto
            padding: 0%
        img
            width: 370px
            @media(max-width: 500px)
                width: 60vw
        ol
            list-style-type: none
            padding: 0% !important
            margin: 0% !important
            display: flex
            width: 30%
            justify-content: space-evenly
            li
                a
                    text-decoration: none
                    color: black
                    &:hover
                        color: #0057E7









