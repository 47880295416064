.popup-container {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 999;
  }
  
  .popup-button {
    // background-color: gray;
    // background-image: url(https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2008px-Google_%22G%22_Logo.svg.png);
    color: white;
    border: none;
    border-radius: 50%;
    // margin-bottom: 30px;
    width: 60px;
    height: 60px;
    // font-size: 14px;
    cursor: pointer;
  }
  
  .popup-content {
    position: absolute;
    bottom: 80px;
    // left: 100px;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    width: 200px;
  }

.apply-button{
    color: white;
    background-color: #0F9D58;
    border-radius: 5%;
    // width: 40px;
    height: 30px;
    // font-size: 14px;
    cursor: pointer

}
  
.annouce{
    color: white;
    justify-content: center;
    // margin-top: -20px;
    font-weight: 550;
    padding: 0px 5px 5px 5px;
    margin-top: 20px;
    // top: 2%;
    align-items: center;
    position: absolute;

}