.main
    // width: 100%  
    .H-Container
      // width: 100%    
      // scroll-snap-stop: always 
      height: 95vh
      // height: 100%
      font-weight: 500
      background-position: 20% 20%
      background-size: cover
      background-repeat: no-repeat
      display: grid
      grid-template-columns:1fr 1fr 
      grid-template-rows:1fr 1fr 1fr 1fr 1fr 1fr
      
      background-image: url("../../Assets/images/landing.jpg")
      @media(max-width: 1400px)
        display: flex 
        flex-direction: column
        align-items: flex-start
        justify-content: center
      @media(max-width: 800px)
        background-position-x: 100%
      @media(max-width: 550px)
        align-items: center
      li
        // grid-column:1/2
        padding-inline-start: 7rem
        margin: 0%
        color: white
        width: 35%
        text-align: left
        list-style-type: none
        &:nth-child(1)
          grid-column: 1
          grid-row: 3/4
        &:nth-child(2)
          grid-column: 1
          grid-row: 4/5
          p
            // color:#c1c0c0
        &:nth-child(3)
          grid-column: 1
          grid-row: 5/6
        &:nth-child(4)
          grid-column: 2
          grid-row: 2/6
          padding-right: 4%
          margin-right: 2%
          padding-left: 4%
          border-radius: 25px
          justify-self: end
          display: flex
          flex-direction: column
          justify-content: space-evenly
          flex-wrap: wrap
          align-items: flex-end
          // background-color: #01040d6d
          // opacity: 70%
          width: fit-content
          svg
            color: white
            border-radius:50px
            background-color: #01040d6d
            display:inline-block
            padding:13px
            transform: scale(1.5)
            // width: 2rem
          @media(max-width: 1400px)
            display: none
        @media(max-width: 550px)
          width: 100%
          text-align: center
          padding-inline-start: 0rem
        p
          padding-bottom: 4%
          margin-block-start: 0em
          margin-block-end: 0em
          margin-inline-start: 0px
          margin-inline-end: 0px
          font-size: 1.8rem
          font-weight: 400
          position: relative
          width: 500px
          @media(max-width: 730px)
            font-size: 1.5rem
          @media(max-width: 600px)
            font-size: 1.3rem
            width: 500px
          @media(max-width: 420px)
            width: 390px
          @media(max-width: 550px)
            margin: auto
          b
            font-size: 2.4rem
        button
          background-color: transparent
          color: white
          border: 2px solid white
          padding: 3.5%
          font-weight: 500
          font-size: 1.4rem !important
          font-family: inherit
          &:hover 
            background-color: #ffffff32
          @media(max-width: 550px)
            margin: auto
        ol
          // grid-row: 1
          // grid-column:2/3
          display: flex
          flex-direction: row
          position: absolute
          right: 0%
          li
    