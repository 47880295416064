
h4 , p{
  font-family: 'Poppins', sans-serif;
  // padding: 20px;
}
.content {
 
  // margin-top: 10%;
  margin-bottom: 10%;
  display:flex;
  padding: 150px;
  justify-content:center;

  font-family:arial;
}
.content i {
  margin-bottom: 41px;
}

// .parent{
//   width: ;
// }
.container {
  background: #fff;
  border-radius: 1px;
  box-sizing: border-box;
  color: #090909;
  font-family: sans-serif;
  padding: 50px 40px 20px;
  text-align: center;

}

.container svg {
  width: 16px;
  height: auto;
}

.content__form {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}

.content__inputs {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.content__form label {
  font-family: 'Poppins', sans-serif;
  border: 1px dotted rgb(219, 219, 219);
  box-shadow: inset;
  display: flex;
  align-items: center;
  position: relative;
  min-width: 268px;
  height: 38px;
  background: rgb(250, 250, 250);
  border-radius: 3px;
}

.content__form span {
  position: absolute;
  text-overflow: ellipsis;
  transform-origin: left;
  font-size: 12px;
  left: 8px;
  bottom: 6px;
  pointer-events: none;
  transition: transform ease-out .1s
  // font-family: 'Poppins', sans-serif;

}

.content__form input {
  width: 100%;
  background: inherit;
  border: 0;
  outline: none;
  padding: 9px 8px 7px 8px;
  text-overflow: ellipsis;
  font-size: 16px;
  vertical-align: middle;
  font-family: 'Poppins', sans-serif;

}

.content__form input:valid+span {
  transform: scale(calc(10 / 12)) translateY(-10px);
}

.content__form input:valid {
  padding: 14px 0 2px 8px;
  font-size: 12px;
}

.content__or-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-size: 13px;
  column-gap: 18px;
  margin-top: 18px;
  font-family: 'Poppins', sans-serif;

}

.content__or-text span:nth-child(3),
.content__or-text span:nth-child(1) {
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgb(219, 219, 219);
}

.content__forgot-buttons {
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  row-gap: 21px;
  
}

.content__forgot-buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  color: #00376b;
  
}

.content__forgot-buttons button:first-child {
  color: #385185;
  font-size: 14px;
  font-weight: 600;
}

.content__form button {
  background: rgb(0, 149, 246);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  padding: 7px 16px;
  cursor: pointer;
}

.content__form button:hover {
  background: rgb(24, 119, 242);
}

.content__form button:active:not(:hover) {
  background: rgb(0, 149, 246);
  opacity: .7;
}

input {
  margin-top: 1rem;
}

input::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  padding: 0.5em;
  border: thin solid grey;
  border-radius: 3px;
}

/* Custom dropdown button styles */
.custom-dropdown {
  position: relative;
  display: inline-block;
}

.custom-dropdown select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: 0;
  outline: none;
  font-size: 12px;
  color: #090909;
  padding: 9px 8px 7px 8px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
}

.custom-dropdown::before {
  content: "▼";
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  font-size: 12px;
  color: #090909;
  pointer-events: none;
}
