.E-container
    color: #404040
    .E-1
        height: 30vh
        background-size: cover
        background-repeat: no-repeat
        display: flex
        align-items: center
        justify-content: center
        h1
            color: white
            font-size: 8vh
    .yellow
        background-image: url(../../../Assets/images/yellow_banner.png)
    .green
        background-image: url(../../../Assets/images/green_banner.png)
    .E-2
        list-style-type: none
        display: flex
        padding: 2.5%
        .E-2-1
            display: flex
            gap: 30px
            li
                font-size: 1.07rem
                a
                    padding-bottom: 16px
                    color: inherit
                    text-decoration: none
                    &:hover
                        color: #4285F4
                        // border-bottom: 1px solid #4285F4
                        // border-bottom-width: 100%
                    &:active
                        border-bottom: 1px solid #4285F4
                        border-bottom-width: 100%

        .E-2-2
            margin-left: auto
            margin-right: 0%
            display: flex
            line-height: 28px
            align-items: center
            position: relative
            right: 4%
            text-align: left
            // max-width: 190px
            li
                input
                    width: 100%
                    height: 40px
                    line-height: 28px
                    padding: 0 1rem
                    padding-left: 1rem
                    border: 2px solid transparent
                    border-radius: 20px
                    outline: none
                    background-color: #fff
                    color: #0d0c22
                    transition: .3s ease
                    box-shadow: 2px 2px 18px 0px rgba(5, 5, 5, 0.062)
                &::placeholder
                    color: #9e9ea7
                &::-webkit-input-placeholder
                    font: inherit
                    font-size: 1rem
                    font-weight: 100
                
            li
                svg
                    fill: #676C72
                // position: absolute
                // left: 1rem
                // width: 1rem
                // height: 1rem

            //
            // li
            //     input
            //         border: 0px 
            //         &:focus
            //            border: none
            //        
    li
        svg
