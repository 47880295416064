.H-Sc-C
    margin: auto
    padding: 4% 0% 0% 0%
    height: fit-content
    h3
      letter-spacing: 0.3rem
      margin-bottom: 0.7%
      // margin-bottom: 2%
    h1
      font-size: 3rem
      letter-spacing: 0.6rem
      color: #404040
      margin-top: 0%
      @media(max-width: 450px)
        font-size: 2rem
        letter-spacing: 0.3rem
      @media(max-width: 300px)
        font-size: 1.5rem
    p
      font-size: 1.4rem
      color: #5c5757
    table
      // background: red
      margin: auto
      width: 75vw
      // border: 1px solid black
      border-collapse: collapse
      tr
          padding: 5%
          display: grid
          // grid-template-columns: 1fr 1fr 1fr 1fr 
          grid-template-columns: repeat(auto-fit, minmax(220px, 1fr))
          gap: 15px
          td  
              border-right: 2px solid #404040
              @media(max-width: 1200px)
                border-right: none
              &:last-child
                border-right: none
                
              li
                  line-height: 5rem
                  list-style: none
                  @media(max-width: 720px)
                    line-height: 3.5rem
                  @media(max-width: 350px)
                    line-height: 3rem
                  &:nth-child(1)
                      font-size: 5rem
                      color: white
                      font-weight: 700
                      @media(max-width: 720px)
                        font-size: 4rem
                      @media(max-width: 420px)
                        font-size: 3rem
                  &:nth-child(2)
                      font-size: 1.5rem
                      font-weight: bold
                      color: #404040
                      @media(max-width: 720px)
                        font-size: 1rem
.insight
  background-color: #d2d2d2
#H-Sc
    padding: 0%
    padding-top:3%
    padding-bottom:3%
    p
      @media(max-width: 768px)
        font-size: 1.1rem
      @media(max-width: 375px)
        // font-size:1rem
      @media(max-width: 425px)
        font-size:1rem
    table
      width: 100vw
      @media(max-width: 1024px)
        margin: 0% !important
      tr
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr))
        row-gap: 20px
        justify-content: center
        margin: auto
        width: 100%
        padding: 2%  0%
        td
          display: flex
          flex-direction: column
          align-items: center
          border-color: #404040
          // height:-moz-fit-content
          // height: fit-content
          padding: 0% 10%
          li
            width: -moz-fit-content
            width: fit-content
            height: -moz-fit-content
            height: fit-content
            line-height: 2rem
            &:first-child
              color: #404040
              font-size: 2rem
              font-weight: 700
              padding-bottom: 8%
              @media(max-width: 768px )
                font-size: 1.7rem
              @media(max-width: 375px )
              @media(max-width: 425px )
                font-size:1.5rem
            &:last-child
              font-size: 1.1rem
              line-height: 1.3rem
              font-weight: 400
              @media(max-width: 768px)
                font-size: 0.9rem
              @media(max-width: 375px)
              @media(max-width: 425px)
                font-size: 0.9rem
      