.carousel-buttons
  display: flex
  justify-content: center
  margin-top: 20px

.carousel-button
  display: flex
  align-items: center
  justify-content: center
  background: rgba(255, 255, 255, 0.3)
  border: none
  color: white
  font-size: 50px
  width: 50px
  height: 50px
  border-radius: 50%
  cursor: pointer
  margin: 0 10px
  backdrop-filter: blur(10px)
  transition: background 0.3s ease

  &:hover
    background: rgba(255, 255, 255, 0.5)


.tag
    // width: 70%
    margin-bottom: 7%
    margin-top: 4%
    margin-left: auto
    margin-right: auto
    overflow: hidden
    @media (max-width: 600tp)
        margin-bottom: 15%
        margin-top: 7%
    .imgslider
        width: 100%
        height: -moz-fit-content
        height: fit-content
        margin-left: auto
        margin-right: auto
        .slide
            // width: 160px !important
            width: -moz-fit-content
            width: fit-content !important
            width: -moz-fit-content
            object-fit: contain
            margin: 2%
            // aspect-ratio: 1
            text-align: center
            @media (max-width: 600px)
                // width: 110px !important
                outline-width: 0px
                text-align: center
            img
                
                border-radius: 10px
                width: 100%
                height: 100%
                object-fit: contain
.team-container
  text-align: center

  h1
    font-size: 24px
    margin-bottom: 20px

.card-container
 
  overflow: hidden
  display: flex
  justify-content: center
  height:auto
  align-items: center
   


  .card
    width: -moz-fit-content
    width: fit-content !important
    width: -moz-fit-content
    object-fit: contain
    margin: 2%
      // aspect-ratio: 1
    text-align: center
    justify-content:center

    img
      border-radius: 10px
      width: 40%
      height: 40%
      object-fit: contain
      // border-radius:200px

    h3
      font-size: 20px
      margin-bottom: 5px
      color:black

    p
      font-size: 16px
      color: #777
